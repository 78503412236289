<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="feature" class="feature-block">
    <uds-icon class="close-icon" icon-name="close" size="medium" @click="close" />

    <div class="content">
      <span class="uds-headers --h3">{{ feature.title }}</span>
      <div class="uds-paragraph fix" v-html="feature.content" />
      <div class="buttons-container">
        <uds-main-button type="primary" class="action" @click="handleAction()">
          {{ feature.cta }}
        </uds-main-button>
        <uds-main-button type="link" @click="close">Ne plus afficher</uds-main-button>
      </div>
    </div>
    <img
      v-if="feature.image || feature.imageDist"
      :src="urlImg"
      style="max-width: 180px; max-height: 180px"
      loading="lazy"
    >
    <div v-else-if="feature.componentImage" style="max-width: 180px; max-height: 180px">
      <component :is="svgPath" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { VisualizedInformationState } from "@silexpert/core";
import { filename } from "pathe/utils";
import type { NewFeature } from "../../content/features/type.js";

const societyStore = useSocietyStore();

const emit = defineEmits<{
  (e: "close"): void;
  (e: "triggerModal", value: string): void;
}>();
const props = defineProps<{
  feature: NewFeature;
}>();

const isLoading = ref(false);
// @ts-expect-error
const glob = import.meta.glob("@/assets/img/**", { eager: true });
const images = Object.fromEntries(
  Object.entries(glob).map(([key, value]: [string, any]) => [filename(key), value.default]),
);

const urlImg = computed<string>(() => {
  return props.feature.imageDist ?? images[`${props.feature.image}`];
});

const svgPath = computed(() => {
  if (isDefined(props.feature.componentImage)) {
    return defineAsyncComponent(
      // @ts-expect-error
      () => import(`@/components/svg/${props.feature.componentImage}.vue`),
    );
  }
  return null;
});

async function handleAction() {
  isLoading.value = true;
  await $silex()
    .visualizedInformation.updateOrCreate(societyStore.society!.id!, {
      key: props.feature.key,
      state: VisualizedInformationState.APPROVED,
    })
    .then(async () => {
      if (props.feature.actionType === "redirection" && props.feature.link) {
        await navigateTo(props.feature.link);
      } else if (props.feature.actionType === "redirection" && props.feature.externalLink) {
        window.open(props.feature.externalLink, "_blank");
      } else if (props.feature.actionType === "openModal" && props.feature.modaleLink) {
        emit("triggerModal", props.feature.modaleLink);
      }

      emit("close");
    })
    .catch((error) => {
      $notifier().open({
        type: "error",
        content: apiErrorToString(error),
      });
    })
    .finally(() => (isLoading.value = false));
}

async function close() {
  if (isNotDefined(societyStore.society) || isNotDefined(societyStore.society.id)) {
    $notifier().open({
      content: "Société inconnue, merci de vous reconnecter",
    });
    return;
  }
  await $silex().visualizedInformation.updateOrCreate(societyStore.society.id, {
    key: props.feature.key,
    state: VisualizedInformationState.CANCELED,
  });
  emit("close");
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
}
.feature-block {
  display: flex;
  gap: $uds-spacing-3;
  align-items: center;
  position: relative;

  font-weight: $uds-weight-450;
  font-size: 16px;
  background: $uds-white;
  border-radius: $uds-radius-2;
  padding: $uds-spacing-4;
  padding-bottom: 0px;

  .content {
    display: flex;
    flex-direction: column;
    gap: $uds-spacing-2;
    width: 360px;

    .fix {
      height: 90px;
      overflow-y: auto;
    }

    .action {
      max-width: max-content;
    }
  }

  :deep(a) {
    text-decoration: underline;
    color: $uds-neutral-900;
  }

  .close-icon {
    position: absolute;
    top: $uds-spacing-1;
    right: $uds-spacing-1;
    cursor: pointer;
  }
}
</style>
